html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  flex: 1;
}

body {
  min-width: 1200px;
  overflow: auto;
}

#root {
  height: 100%;
  display: flex;
}

.full-width-panel {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
}

.bp4-tab-list {
  overflow: auto;
  scrollbar-width: 4px;
  transition: height 5s ease-in-out;
}

.bp4-tab-list > *:focus {
  outline: none;
}

.bp4-tab-list::-webkit-scrollbar {
  height: 4px;
}

.bp4-tab-list:hover::-webkit-scrollbar {
  height: 10px;
}

.bp4-tab-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.bp4-tab-list::-webkit-scrollbar-thumb {
  background: #aeaeae;
}

.bp4-tab-list::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.jexcel > thead > tr > td {
  z-index: 31;
  top: -1px;
}

.jexcel > thead > tr:nth-of-type(2) > td {
  top: 24px;
}

.jexcel > thead > tr:nth-of-type(3) > td {
  top: 48px;
}

.jexcel > thead > tr:nth-of-type(4) > td {
  top: 72px;
}

.jexcel > thead > tr:nth-of-type(4) > td {
  top: 96px;
}

.fast-option {
  line-height: 15px !important;
  display: flex;
  align-items: center;
}

.bullet-list-popover .bp4-popover-content {
  background: white !important;
}

.bullet-list-popover .bp4-popover-arrow-fill {
  fill: white !important;
}

.alert-dialog {
  z-index: 99999;
}
